<template>
    <div class="embed-view">
        <CardComponent>
            <template #header>
                <RowComponent align="center">
                    <HelpComponent
                        markdown="embed-code"
                        external-link="https://www.surveylegend.com/user-guide/embed-surveys-forms-website-blog/"
                    />
                    <span>Inline frame embed code</span>
                </RowComponent>
            </template>
            <RowComponent justify="space-between" align="center">
                <RowComponent align="center">
                    <HelpComponent
                        markdown="transparent-iframe"
                        external-link="https://www.surveylegend.com/user-guide/embed-surveys-forms-website-blog/#transparent-background"
                    />
                    <span>Transparent background for inline frame</span>
                </RowComponent>
                <ToggleComponent
                    v-model="survey.iframeTransparentBackground"
                    prevent
                    @change="handleChange($event, 'iframeTransparentBackground')"
                />
            </RowComponent>
            <PrismComponent
                :code="survey.iframeTransparentBackground ? embedCodeTransparent : embedCode"
                language="markup"
            >
                <template #toolbar>
                    <CopyComponent
                        :value="
                            survey.iframeTransparentBackground ? embedCodeTransparent : embedCode
                        "
                        size="small"
                        translucent
                    />
                </template>
            </PrismComponent>
        </CardComponent>
        <CardComponent>
            <template #header>
                <RowComponent justify="space-between" align="center">
                    <RowComponent align="center">
                        <HelpComponent
                            markdown="iframe-notification"
                            external-link="https://www.surveylegend.com/user-guide/embed-surveys-forms-website-blog/#submit-notification"
                        />
                        <span>Inline frame notification on submit</span>
                    </RowComponent>
                    <template v-if="!survey.hideSubmitButton">
                        <ToggleComponent
                            v-model="survey.iframeNotifications"
                            prevent
                            @change="handleChange($event, 'iframeNotifications')"
                        />
                    </template>
                    <template v-else>
                        <PopoverComponent
                            v-model:visible="visiblePopover"
                            width="300px"
                            placement="top-end"
                            :close-delay="0"
                        >
                            <template #trigger>
                                <ToggleComponent
                                    :value="false"
                                    locked
                                    @mouseenter="visiblePopover = true"
                                    @mouseleave="visiblePopover = false"
                                />
                            </template>
                            <p>
                                You <strong>cannot</strong> enable this when submit button is
                                hidden.
                            </p>
                        </PopoverComponent>
                    </template>
                </RowComponent>
            </template>
            <template v-if="survey.iframeNotifications" #default>
                <RowComponent justify="space-between" align="center">
                    <RowComponent align="center">
                        <HelpComponent
                            markdown="include-response"
                            external-link="https://www.surveylegend.com/user-guide/embed-surveys-forms-website-blog/#submit-notification"
                        />
                        <span>Include responses in notification</span>
                    </RowComponent>
                    <ToggleComponent
                        v-model="survey.iframeNotificationsIncludeResponses"
                        prevent
                        @change="handleChange($event, 'iframeNotificationsIncludeResponses')"
                    />
                </RowComponent>
                <PrismComponent
                    :code="
                        survey.iframeNotificationsIncludeResponses ? notifyCodeResponse : notifyCode
                    "
                    language="javascript"
                >
                    <template #toolbar>
                        <CopyComponent
                            :value="
                                survey.iframeNotificationsIncludeResponses
                                    ? notifyCodeResponse
                                    : notifyCode
                            "
                            size="small"
                            translucent
                        />
                    </template>
                </PrismComponent>
            </template>
        </CardComponent>
        <Teleport to="body">
            <UpgradeComponent
                ref="upgrade-embedded-transparent-background"
                name="embedded-transparent-background"
                image="embedded-transparent-background.jpg"
            />
            <UpgradeComponent
                ref="upgrade-embedded-notifications"
                name="embedded-notifications"
                image="embedded-notifications.png"
            />
            <WarningComponent
                ref="warning-include-response"
                name="include-response"
                word="include"
            />
        </Teleport>
    </div>
</template>

<script>
import { db } from '@/firebase'
import { mapGetters } from 'vuex'

const surveys = db.ref('v2/survey_meta/')

/* eslint-disable */
const notifyCode = `<script>
    window.addEventListener("message", onReceiveSurveySubmittedMessage, false);
    function onReceiveSurveySubmittedMessage(event) {
        // Important to verify that message is really coming from www.surveylegend.com
        if (event.origin === 'https://www.surveylegend.com' &&
            event.data && // null is also of type "object"
            typeof event.data === 'object' &&
            event.data.type === 'submit-responses'
        ) {
            /***
             * Data passed from surveylegend survey iframe
             */
            /*
            event.data = {
                type: 'submit-responses',
                responseId: 'id generated by SurveyLegend',
                surveyId: 'survey id',
                externalId: 'custom id passed in url'
                completedTime:'2018-09-07 HH:mm:ss ...'
            }
            */
            // TODO: add code to run after survey submitted
            /***
             * Example 1: Redirect to another web page
             */
            /*
            window.location.href = 'https://example.com';
            */
            /***
             * Example 2: Remove iframe containing survey
             */
            /*
            var surveyIframe = document.getElementById('surveylegend-survey');
            if (surveyIframe.length > 0) {
                //surveyIframe.remove(); // remove() method does not work in any versions of Internet Explorer browser
                surveyIframe.parentNode.removeChild(surveyIframe);
            }
            */
            /***
             * Example 3: Send data to your own custom system
             */
            /*
            var surveyResponse = event.data;
            var payload = JSON.stringify(surveyResponse);
            var httpRequest = new XMLHttpRequest();
            httpRequest.open("POST", 'https://example.com', true);
            httpRequest.setRequestHeader('Content-Type', 'application/json');
            httpRequest.send(payload);
            */
        }
    }
<\/script>`

const notifyCodeResponse = `<script>
    window.addEventListener("message", onReceiveSurveySubmittedMessage, false);
    function onReceiveSurveySubmittedMessage(event) {
        // Important to verify that message is really coming from www.surveylegend.com
        if (event.origin === 'https://www.surveylegend.com' &&
            event.data && // null is also of type "object"
            typeof event.data === 'object' &&
            event.data.type === 'submit-responses'
        ) {
            /***
             * Data passed from surveylegend survey iframe
             */
            /*
            event.data = {
                type: 'submit-responses',
                responseId: 'id generated by SurveyLegend',
                surveyId: 'survey id',
                externalId: 'custom id passed in url'
                completedTime:'2018-09-07 HH:mm:ss ...'
                responses: {
                    questionId1: {
                        ...
                        value: "respondent input"
                        ...
                    },
                    ...
                }
            }
            */
            // TODO: add code to run after survey submitted
            /***
             * Example 1: Redirect to another web page
             */
            /*
            window.location.href = 'https://example.com';
            */
            /***
             * Example 2: Remove iframe containing survey
             */
            /*
            var surveyIframe = document.getElementById('surveylegend-survey');
            if (surveyIframe.length > 0) {
                //surveyIframe.remove(); // remove() method does not work in any versions of Internet Explorer browser
                surveyIframe.parentNode.removeChild(surveyIframe);
            }
            */
            /***
             * Example 3: Send data to your own custom system
             */
            /*
            var surveyResponse = event.data;
            var payload = JSON.stringify(surveyResponse);
            var httpRequest = new XMLHttpRequest();
            httpRequest.open("POST", 'https://example.com', true);
            httpRequest.setRequestHeader('Content-Type', 'application/json');
            httpRequest.send(payload);
            */
        }
    }
<\/script>`
/* eslint-enable */

export default {
    name: 'Embed',

    metaInfo: {
        title: 'Embed'
    },

    data() {
        return {
            survey: {
                iframeTransparentBackground: false,
                iframeNotifications: false,
                iframeNotificationsIncludeResponses: false
            },
            visiblePopover: false,
            notifyCode,
            notifyCodeResponse
        }
    },

    computed: {
        ...mapGetters({
            permissions: 'auth/permissions'
        }),

        embedCode() {
            return `<iframe
    id="surveylegend-survey"
    src="${window.location.origin}/survey/${this.$route.params.id}"
    width="100%"
    height="1000px"
    style="frameborder: 0; border: 0; margin: 0 auto;"
>
</iframe>`
        },

        embedCodeTransparent() {
            return `<iframe
    id="surveylegend-survey"
    src="${window.location.origin}/survey/${this.$route.params.id}"
    width="100%"
    height="1000px"
    allowtransparency="true"
    style="frameborder: 0; border: 0; margin: 0 auto; background: transparent; background-color: transparent;"
>
</iframe>`
        }
    },

    watch: {
        '$route.params.id': {
            immediate: true,
            handler(id) {
                this.$rtdbBind('survey', surveys.child(id))
            }
        }
    },

    methods: {
        handleSave(data) {
            this.$firebaseRefs.survey.update(data)
        },

        async handleChange({ value }, toggle) {
            let permission
            let hasPermission
            let name

            if (value) {
                switch (toggle) {
                    case 'iframeTransparentBackground':
                        permission = 'embedded-transparent-background'
                        hasPermission = this.permissions[permission]

                        break
                    case 'iframeNotifications':
                        permission = 'embedded-notifications'
                        hasPermission = this.permissions[permission]

                        break
                    case 'iframeNotificationsIncludeResponses':
                        name = 'include-response'

                        permission = 'embedded-notifications'
                        hasPermission = this.permissions[permission]

                        if (hasPermission > 0) {
                            const isConfirmed = await this.$refs[`warning-${name}`].open()

                            this.$refs[`warning-${name}`].close()

                            if (!isConfirmed) return
                        }

                        break
                    default:
                        hasPermission = true
                }

                if (!hasPermission) {
                    this.track('open-upgrade-modal', permission)

                    const isConfirmed = await this.$refs[`upgrade-${permission}`].open()

                    this.$refs[`upgrade-${permission}`].close()

                    if (isConfirmed)
                        window.location.href = `https://www.surveylegend.com/upgrade?source=upgrade_modal&type=${permission}&level=${this.permissions.level}`

                    return
                } else if (permission) {
                    this.track('has-permission', permission)
                }
            }

            const data = {}

            data[toggle] = value

            this.handleSave(data)
        },

        track(action, name) {
            this.$gtag.event(action, {
                event_category: name,
                event_label: this.permissions.level
            })
        }
    }
}
</script>

<style scoped lang="scss">
.embed-view {
    .prism-component {
        margin-top: 25px;
    }
}
</style>
